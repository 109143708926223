import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { FaroButton, FaroText, neutral, TextField } from "@faro-lotv/flat-ui";
import { Box, Checkbox, Drawer, FormControlLabel, Stack } from "@mui/material";
import { useState } from "react";

/**
 * @returns a sidebar next to the canvas, used for the create area workflow
 */
export function ClippingBoxModeDrawer(): JSX.Element | null {
  const [shouldCreateAnotherArea, setShouldCreateAnotherArea] = useState(false);

  const shouldShowDrawer = useAppSelector(
    selectHasFeature(Features.CreateArea),
  );

  if (!shouldShowDrawer) return null;

  return (
    <Drawer
      open
      variant="persistent"
      sx={{
        "& .MuiDrawer-paper": {
          position: "unset",
          width: 300,
          backgroundColor: neutral[50],
          p: 1.5,
          gap: 3,
        },
      }}
    >
      <FaroText variant="heading16">Areas</FaroText>
      <FaroText variant="placeholder">
        Fill and check the information about your area.
      </FaroText>
      <Stack
        sx={{
          p: 1.5,
          gap: 2,
          borderRadius: 0.5,
          backgroundColor: neutral[0],
        }}
      >
        <TextField
          label="Name"
          placeholder="Area 1"
          // Stop the box controls from being triggered
          onKeyDown={(e) => e.stopPropagation()}
        />
        <Stack>
          <FaroText variant="labelM">Area overview image</FaroText>
          {/*
           * TODO: Replace the box with a real overview image
           * https://faro01.atlassian.net/browse/SWEB-5265
           */}
          <Box
            component="img"
            sx={{
              width: "100%",
              aspectRatio: 1.75,
              backgroundColor: neutral[200],
            }}
          />
          <FaroText variant="helpText">
            An image of the area will be automatically generated
          </FaroText>
          <FormControlLabel
            label="Create another area"
            control={
              <Checkbox
                checked={shouldCreateAnotherArea}
                onChange={(ev) => setShouldCreateAnotherArea(ev.target.checked)}
              />
            }
            sx={{
              my: 2,
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: neutral[800],
              },
            }}
          />
          <FaroButton>Create</FaroButton>
        </Stack>
      </Stack>
    </Drawer>
  );
}
