import { Mode } from "@/modes/mode";
import { changeMode } from "@/store/mode-slice";
import { deactivateTool } from "@/store/ui/ui-slice";
import { ClippingBoxModeDrawer } from "./clipping-box-mode-drawer";
import { ClippingBoxModeOverlay } from "./clipping-box-mode-overlay";
import { ClippingBoxModeScene } from "./clipping-box-mode-scene";
import { ClippingBoxModeTransition } from "./clipping-box-mode-transition";

export const clippingBoxMode: Mode = {
  name: "clippingbox",
  ModeScene: ClippingBoxModeScene,
  ModeOverlay: ClippingBoxModeOverlay,
  ModeTransition: ClippingBoxModeTransition,
  ModeDrawer: ClippingBoxModeDrawer,
  exclusive: {
    title: "Define Volume",
    // eslint-disable-next-line require-await -- FIXME
    async onBack({ dispatch }) {
      dispatch(changeMode("overview"));
      dispatch(deactivateTool());
    },
  },
};
