import { EasterEgg } from "@/components/EasterEgg";
import { ClipboxEventsContextProvider } from "@/components/common/clipbox-events-context";
import { useDeepLinkRequiredElement } from "@/components/common/deep-link/deep-link-context";
import { ElementFileUploadContextProvider } from "@/components/common/point-cloud-file-upload-context/element-file-upload-context";
import { ProjectProvider } from "@/components/common/project-provider/project-provider";
import { RenderingSettingsContextProvider } from "@/components/common/rendering-settings-context";
import { SceneEventsContextProvider } from "@/components/common/scene-events-context";
import { AppBanner } from "@/components/ui/app-banner";
import { BackgroundTaskNotifier } from "@/components/ui/background-task-notifier";
import { ExclusiveHeaderBar } from "@/components/ui/exclusive-header-bar";
import { ModalSpinner } from "@/components/ui/modal-spinner";
import { ModeCanvas } from "@/components/ui/mode-canvas";
import { ProgressOverviewMenu } from "@/components/ui/progress-overview/progress-overview-menu";
import { ProjectBreadcrumbs } from "@/components/ui/project-breadcrumbs";
import {
  ProjectOverview,
  ProjectOverviewBase,
} from "@/components/ui/project-overview";
import { QuickHelpButton } from "@/components/ui/quick-help-button";
import { ShareLinkButton } from "@/components/ui/share-link-button";
import { TreeContextProvider } from "@/components/ui/tree/tree-context";
import { useDocumentTitle } from "@/hooks/use-document-title";
import { useOpenAccountAndSecurity } from "@/hooks/use-open-account-and-security";
import { getMode } from "@/modes";
import { ModeDataProvider } from "@/modes/mode-data-context";
import { EnsureProjectAccess } from "@/permissions/ensure-project-access";
import { runtimeConfig } from "@/runtime-config";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { selectModeName } from "@/store/mode-selectors";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanUseSendToTool } from "@/store/subscriptions/subscriptions-selectors";
import { selectShowSpinner } from "@/store/ui/ui-selectors";
import { selectCurrentUser } from "@/store/user-selectors";
import { appId } from "@/utils/appid";
import { APP_VERSION } from "@/utils/env-constants";
import { ProgressApiTracker } from "@/utils/progress-api-tracker";
import {
  HeaderBar,
  LoadingScreen,
  useBreakpointMdUp,
} from "@faro-lotv/flat-ui";
import { useAnalyticsInitialization } from "@faro-lotv/foreign-observers";
import { useAuthContext } from "@faro-lotv/gate-keepers";
import {
  selectDashboardUrl,
  selectProjectName,
} from "@faro-lotv/project-source";
import { ApiClientContextProvider } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { omit } from "lodash";
import { useState } from "react";
import { useParams } from "react-router";

/**
 * @returns Loads the project with the provided UI from the API, and then renders the main UI
 */
export function ProjectPage(): JSX.Element | null {
  const { projectId } = useParams();
  const currentUser = useAppSelector(selectCurrentUser);

  if (!projectId) {
    throw Error("No project ID provided");
  }
  const [isReady, setIsReady] = useState(false);

  const deepLinkRequiredElement = useDeepLinkRequiredElement();

  useAnalyticsInitialization(
    runtimeConfig.analytics.amplitudeApiKey,
    currentUser?.email,
  );

  const showLoadingScreen = !isReady;

  return (
    <ApiClientContextProvider
      projectId={projectId}
      userId={currentUser?.id}
      clientId={appId()}
      apiBaseUrls={runtimeConfig.backendEndpoints}
    >
      <EnsureProjectAccess projectId={projectId}>
        <ProjectProvider
          requiredItem={deepLinkRequiredElement}
          projectId={projectId}
          onReady={setIsReady}
        >
          <ModeDataProvider>
            <ElementFileUploadContextProvider>
              <SceneEventsContextProvider>
                {/* The context with the events for the clipping box is placed here because it has to be shared
                 * by both the overview scene and the overview overlay components, and maybe in the future even other
                 * modes will use the clipscene tool. */}
                <ClipboxEventsContextProvider>
                  <RenderingSettingsContextProvider>
                    {!showLoadingScreen && <MainUi />}
                  </RenderingSettingsContextProvider>
                </ClipboxEventsContextProvider>
              </SceneEventsContextProvider>
              <ProgressApiTracker />
            </ElementFileUploadContextProvider>
          </ModeDataProvider>
        </ProjectProvider>

        {showLoadingScreen && (
          <Stack
            direction="row"
            sx={{ width: "100%", height: "100%" }}
            justifyContent="center"
          >
            <LoadingScreen />
          </Stack>
        )}
      </EnsureProjectAccess>
    </ApiClientContextProvider>
  );
}

/**
 * @returns The main UI layout for the app
 */
function MainUi(): JSX.Element {
  const modeName = useAppSelector(selectModeName);
  const mode = getMode(modeName);
  const showSpinner = useAppSelector(selectShowSpinner);

  const currentUser = useAppSelector(selectCurrentUser);
  const projectName = useAppSelector(selectProjectName);

  const { requestLogin, logout } = useAuthContext();

  const openAccountAndSecurity = useOpenAccountAndSecurity();

  const isMdUp = useBreakpointMdUp();

  useDocumentTitle(projectName);

  const isSendToToolEnabled = useAppSelector(
    selectHasFeature(Features.SendToTool),
  );

  const canShareCurrentScene = !mode.exclusive && mode.name !== "start";

  const hasSendToSubscription = useAppSelector(selectCanUseSendToTool);

  const additionalFeedbackLinks =
    isSendToToolEnabled && hasSendToSubscription
      ? [
          {
            label: "SendTo",
            // TODO: provide sendto feed back form link via cluster configuration see https://faro01.atlassian.net/browse/S2BIM-315
            link: "https://forms.office.com/r/jQRx4SH57T",
          },
        ]
      : undefined;

  // Only use the backend defined dashboard url so guest users of unlisted project
  // will not get redirected to the old HB dashboard by mistake
  const dashboardUrl = useAppSelector(selectDashboardUrl);
  const links = {
    ...omit(runtimeConfig.externalLinks, "dashboardUrl"),
    dashboardUrl,
  };

  return (
    <TreeContextProvider>
      <Stack sx={{ height: "100%", width: "100%", overflow: "hidden" }}>
        {mode.exclusive && !mode.exclusive.useDefaultHeader ? (
          <ExclusiveHeaderBar
            {...mode.exclusive}
            hasQuickHelp={!!mode.ModeQuickHelpDrawer}
          />
        ) : (
          <HeaderBar
            userDisplayInfo={currentUser}
            links={links}
            appVersion={APP_VERSION}
            additionalFeedbackForms={additionalFeedbackLinks}
            onLogInClick={requestLogin}
            onLogOutClick={logout}
            onAccountAndSecurityClick={openAccountAndSecurity}
            content={<ProjectBreadcrumbs />}
            drawerContent={
              !mode.exclusive && !isMdUp ? <ProjectOverviewBase /> : undefined
            }
          >
            {canShareCurrentScene && <ShareLinkButton />}
            {mode.ModeQuickHelpDrawer && <QuickHelpButton />}
            <ProgressOverviewMenu />
          </HeaderBar>
        )}

        {!mode.exclusive && <AppBanner />}

        <Stack flexGrow={1} flexDirection="row" overflow="auto">
          {!mode.exclusive && isMdUp && <ProjectOverview />}
          <Stack flexGrow={1} overflow="auto">
            <ModeCanvas modeName={modeName} />
          </Stack>
          {mode.ModeDrawer && <mode.ModeDrawer />}
          {mode.ModeQuickHelpDrawer && <mode.ModeQuickHelpDrawer />}
          <BackgroundTaskNotifier />
        </Stack>
      </Stack>
      <ModalSpinner
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      />
      <EasterEgg />
    </TreeContextProvider>
  );
}
