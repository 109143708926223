import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { selectIsPointCloudViewable } from "@/modes/mode-selectors";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  selectCanReadCAD,
  selectCanReadPointCloud,
} from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import { isIElementValidPointCloudStream } from "@/types/type-guards";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectChildrenDepthFirst,
  selectIElement,
  selectIsVideoWalkDataSession,
  useDialog,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import {
  GUID,
  IElementTypeHint,
  isIElementAreaSection,
  isIElementGenericDataset,
  isIElementGenericPointCloud,
  isIElementSectionDataSession,
  isIElementSectionWithTypeHint,
  isIElementTimeseries,
  isIElementTimeseriesDataSession,
  isIElementVideoRecording,
} from "@faro-lotv/ielement-types";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { isEqual } from "lodash";
import { useCallback, useRef } from "react";
import {
  ContextMenuAction,
  ContextMenuActionHandlerArgs,
} from "./action-types";
import { ACTIONS } from "./actions";
import { ContextMenuActionItem } from "./context-menu-action-item";
import { ContextMenuBase, ContextMenuBaseHandle } from "./context-menu-base";
import { selectDownloadablePointCloud } from "./utils";

/**
 * @returns the context menu actions for a specific element
 * @param id selected element id
 * @param isDisabled true if the node is disabled
 */
function selectVisibleContextMenuActions(
  id: GUID,
  isDisabled: boolean = false,
) {
  return (state: RootState): ContextMenuAction[] => {
    const iElement = selectIElement(id)(state);

    const hasMultiCloudRegistrationFeature = selectHasFeature(
      Features.MultiCloudRegistration,
    )(state);

    const userHaveWritePermission = useAppSelector(selectHasWritePermission);

    const hasCadSupport = selectCanReadCAD(state);

    const hasAlignWizardFeatureEnabled = useAppSelector(
      selectHasFeature(Features.AlignWizard),
    );

    if (!iElement) return [];

    if (
      isIElementSectionDataSession(iElement) ||
      isIElementGenericDataset(iElement)
    ) {
      if (selectIsVideoWalkDataSession(id)(state)) {
        return [
          ...(isDisabled ? [] : [ACTIONS.adjustTrajectory]),
          ACTIONS.deleteVideoRecording,
          ...(userHaveWritePermission ? [ACTIONS.editElement] : []),
        ];
      }

      if (!selectCanReadPointCloud(state)) return [];

      // Check if section contains a generic pointcloud (E57, CPE, ...) of the types that allow download.
      const hasDownload = !!selectDownloadablePointCloud(iElement)(state);

      if (isDisabled) {
        return hasDownload
          ? [ACTIONS.download, ACTIONS.delete]
          : [ACTIONS.delete];
      }

      // Check if section contains a generic pointcloud stream (Potree or Webshare) which can be aligned
      const canBeAligned =
        selectChildDepthFirst(
          iElement,
          (el) =>
            isIElementValidPointCloudStream(el) &&
            selectIsPointCloudViewable(el)(state),
        )(state) !== undefined &&
        // Aligning data sets from the capture tree without a data session is not possible yet
        !isIElementGenericDataset(iElement);

      const canBeMoved =
        userHaveWritePermission &&
        // Moving is only supported for data sessions outside of the capture tree
        isIElementSectionDataSession(iElement);

      return [
        // Enable new cloud alignment for those pointclouds that allow it.
        ...(canBeAligned && hasAlignWizardFeatureEnabled
          ? [ACTIONS.openAlignmentWizard]
          : []),

        // Enable alignment for those pointclouds that allow it.
        ...(canBeAligned && !hasAlignWizardFeatureEnabled
          ? [ACTIONS.align]
          : []),

        // Enable new cloud alignment for those pointclouds that allow it.
        ...(canBeAligned && !hasAlignWizardFeatureEnabled
          ? [ACTIONS.alignCloud]
          : []),

        // Enable registration for those pointclouds that allow it.
        ...(canBeAligned ? [ACTIONS.register] : []),

        // Set a cloud as geo-referenced if it was not already
        ...(canBeAligned ? [ACTIONS.setCloudAsGeoReferenced] : []),

        // Enable download for those pointclouds that allow it.
        ...(hasDownload ? [ACTIONS.download] : []),

        // Enable editing elements
        ...(userHaveWritePermission ? [ACTIONS.editElement] : []),
        ...(canBeMoved ? [ACTIONS.moveDataSession] : []),

        ACTIONS.delete,
      ];
    }

    if (isIElementVideoRecording(iElement)) {
      return isDisabled
        ? [ACTIONS.deleteVideoRecording]
        : [ACTIONS.adjustTrajectory, ACTIONS.deleteVideoRecording];
    }

    if (isIElementTimeseriesDataSession(iElement)) {
      const pointClouds = selectChildrenDepthFirst(
        iElement,
        isIElementGenericPointCloud,
      )(state);
      const actions: ContextMenuAction[] = [];
      if (pointClouds.length > 1) {
        actions.push(ACTIONS.inspectAndPublish);
        if (hasMultiCloudRegistrationFeature) {
          actions.push(ACTIONS.registerAll);
        }
      }
      return actions;
    }

    if (isIElementAreaSection(iElement)) {
      return [
        ...(isDisabled ? [] : [ACTIONS.scaleFloor]),
        ...(hasAlignWizardFeatureEnabled && hasCadSupport
          ? [ACTIONS.openAlignmentWizard]
          : []),
        ...(hasCadSupport && !hasAlignWizardFeatureEnabled
          ? [ACTIONS.alignArea]
          : []),
        ...(userHaveWritePermission ? [ACTIONS.editElement] : []),
      ];
    }

    if (
      userHaveWritePermission &&
      isIElementSectionWithTypeHint(iElement, IElementTypeHint.room)
    ) {
      // room sections can be edited only on top level, but not in case if they are children of timeseries
      const timeSeries = selectAncestor(iElement, isIElementTimeseries)(state);
      return timeSeries ? [] : [ACTIONS.editElement];
    }

    return [];
  };
}

/**
 * Invoke the proper handler for the triggered action on the selected element.
 *
 * @param action The context menu action to handle.
 * @param handlerArgs The arguments to pass to the action handler.
 * @returns a Promise to track the action's result
 */
// eslint-disable-next-line require-await -- FIXME
export async function contextMenuHandler(
  action: ContextMenuAction,
  handlerArgs: ContextMenuActionHandlerArgs,
): Promise<void> {
  return action.handler ? action.handler(handlerArgs) : undefined;
}

type ContextMenuProps = {
  /** ID of the tree item */
  id: GUID;

  /** Whether the node of the context menu is currently disabled. */
  isNodeDisabled: boolean;

  /** Callback executed when the menu is opened/closed */
  onToggle(open: boolean): void;
};

/**
 * @returns a context menu for the given tree item
 */
export function ContextMenu({
  id,
  isNodeDisabled,
  onToggle,
}: ContextMenuProps): JSX.Element | null {
  const visibleContextMenuActions = useAppSelector(
    selectVisibleContextMenuActions(id, isNodeDisabled),
    isEqual,
  );

  const menuRef = useRef<ContextMenuBaseHandle>(null);

  const { getState } = useAppStore();
  const dispatch = useAppDispatch();
  const apiClients = useApiClientContext();
  const { createDialog, setConfirmButtonDisabled } = useDialog();
  const { openToast } = useToast();
  const errorHandlers = useErrorHandlers();

  const onContextMenuItemClicked = useCallback(
    // eslint-disable-next-line require-await -- FIXME
    async (elementID: GUID, action: ContextMenuAction) => {
      contextMenuHandler(action, {
        elementID,
        state: getState(),
        dispatch,
        apiClients,
        createDialog,
        openToast,
        errorHandlers,
        setConfirmButtonDisabled,
      }).catch((error) =>
        errorHandlers.handleErrorWithDialog({
          title: `Error: ${action.label}`,
          error,
        }),
      );
    },
    [
      apiClients,
      getState,
      dispatch,
      createDialog,
      openToast,
      errorHandlers,
      setConfirmButtonDisabled,
    ],
  );

  if (!visibleContextMenuActions.length) return null;

  return (
    <ContextMenuBase ref={menuRef} onToggle={onToggle}>
      {visibleContextMenuActions.map((action) => (
        <ContextMenuActionItem
          key={action.type}
          action={action}
          elementId={id}
          onContextMenuItemClicked={onContextMenuItemClicked}
          closeContextMenu={() => menuRef.current?.closeContextMenu()}
        />
      ))}
    </ContextMenuBase>
  );
}
